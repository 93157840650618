import { useEffect, useRef, useState } from "react";
import TournamentTable from "./components/TournamentTable";
import {
  getTournamentList,
  getTournamentSetup,
  postNewTournament,
  postUpdateTournament,
  getTournamentDetails,
} from "../../api";
import moment from "moment";
import { BiLoaderCircle } from "react-icons/bi";
import ListBoxSelect from "./components/ListBoxSelect";
import TournamentTemplate from "./components/TournamentTemplate";
import BracketTemplate from "./components/BracketTemplate";
import CancelPopup from "./components/CancelPopup";

const seriesOption = [
  { title: "s1", value: "s1" },
  { title: "s2", value: "s2" },
];
export default function Manager() {
  const [tounamentList, setTounamentList] = useState([]);
  const [tounamentTemplateList, setTounamentTemplateList] = useState([]);
  const [tounamentBracketList, setTounamentBracketList] = useState([]);
  const [serverRegionList, setServerRegionList] = useState([]);
  const [tournamentDetail, setTournamentDetail] = useState([]);
  const [tounamentTemplate, setTounamentTemplate] = useState({});
  const [tounamentBracket, setTounamentBracket] = useState({});
  const [serverRegion, setServerRegion] = useState({});
  const [series, setSeries] = useState({});
  const [loader, setLoader] = useState(false);
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [regStartDate, setRegStartDate] = useState();
  const [regStartTime, setRegStartTime] = useState();
  const [regEndDate, setRegEndDate] = useState();
  const [regEndTime, setRegEndTime] = useState();
  const [tournamentStartDate, setTournamentStartDate] = useState();
  const [tournamentStartTime, setTournamentStartTime] = useState();
  // const [tournamentEndDate, setTournamentEndDate] = useState();
  // const [tournamentEndTime, setTournamentEndTime] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState({ success: false, msg: "" });
  const [create, setCreate] = useState(false);
  const [type, setType] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const cancel = useRef();
  const [currentTime, setCurrentTime] = useState(
    moment().utc().format("YYYY-MM-DD HH:mm:ss")
  );

  useEffect(() => {
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(moment().utc().format("HH:mm:ss"));
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchTournamentList();
    fetchTournamentSetup();
  }, []);

  useEffect(() => {
    const states = [
      title,
      tournamentStartDate,
      tournamentStartTime,
      // tournamentEndDate,
      // tournamentEndTime,
      regStartDate,
      regStartTime,
      regEndDate,
      regEndTime,
      tounamentTemplate,
      tounamentBracket,
      serverRegion,
    ];
    const isEmpty = states.some((item) => item === "");
    setDisabled(isEmpty);
  }, [
    title,
    tournamentStartDate,
    tournamentStartTime,
    // tournamentEndDate,
    // tournamentEndTime,
    regStartDate,
    regStartTime,
    regEndDate,
    regEndTime,
    tounamentTemplate,
    tounamentBracket,
    serverRegion,
  ]);

  const fetchTournamentList = async () => {
    try {
      setLoader(true);
      let data = await getTournamentList();
      setTounamentList(data?.content);
      setLoader(false);
      setStatus({ status: false, msg: "" });
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const fetchTournamentSetup = async () => {
    try {
      setLoader(true);
      let data = await getTournamentSetup();
      setTounamentTemplateList(data?.content?.tournament_templates);
      setTounamentTemplate(data?.content?.tournament_templates[0]);
      setTounamentBracketList(data?.content?.bracket_system);
      setTounamentBracket(data?.content?.bracket_system[0]);
      setServerRegionList(data?.content?.server_regions);
      setServerRegion(data?.content?.server_regions[0]);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const createTournament = async () => {
    try {
      setLoader(true);

      let regStarts = regStartDate + " " + regStartTime;
      let regEnds = regEndDate + " " + regEndTime;
      let tournamentStarts = tournamentStartDate + " " + tournamentStartTime;
      // let tournamentEnds = tournamentEndDate + " " + tournamentEndTime;

      // Parse the date and time strings into Moment.js objects
      const momentA = moment(regStarts, "YYYY-MM-DD HH:mm");
      const momentB = moment(regEnds, "YYYY-MM-DD HH:mm");
      const momentC = moment(tournamentStarts, "YYYY-MM-DD HH:mm");
      // const momentD = moment(tournamentEnds, "YYYY-MM-DD HH:mm");
      //const currentTime = moment().utc()
      //Compare date and time A with date and time B
      //console.log(moment().utc())
      //debugger
      // if (momentA.isSameOrBefore(currentTime.format('YYYY-MM-DD HH:mm'))) {
      //   setLoader(false);
      //   setError("Registration starts must be after current period");
      // } else if (momentB.isSameOrBefore(momentA)) {
      //   setLoader(false);
      //   setError("Registration ends must be after Registration starts period");
      // } else if (momentC.isSameOrBefore(momentB)) {
      //   setLoader(false);
      //   setError("Tournament starts must be after Registration ends period");
      // } else {
      let data = await postNewTournament(
        tounamentTemplate.id,
        tounamentBracket.id,
        serverRegion.id,
        title,
        description,
        regStarts,
        regEnds,
        tournamentStarts
        // tournamentEnds
      );
      //debugger
      if (data?.data?.result?.status === "1") {
        fetchTournamentList();
        setStatus({ success: true, msg: "Success" });
        setCreate(false);
        setError("");
      } else {
        setError(data.result.err_description);
      }
      console.log(data);
      setLoader(false);
      //}
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const openCreateTournament = async () => {
    setCreate(true);
    setType("create");
    setTournamentDetail({});
    setTounamentTemplate(tounamentTemplateList[0]);
    setTounamentBracket(tounamentBracketList[0]);
    setServerRegion(serverRegionList[0]);
    const currentTimeUTC = moment.utc();

    setRegStartDate(moment.utc().format("YYYY-MM-DD"));
    setRegStartTime(moment.utc().add(4, "minutes").format("HH:mm"));
    setRegEndDate(moment.utc().format("YYYY-MM-DD"));
    setRegEndTime(moment.utc().add(8, "minutes").format("HH:mm"));
    setTournamentStartDate(moment.utc().format("YYYY-MM-DD"));
    setTournamentStartTime(moment.utc().add(12, "minutes").format("HH:mm"));
    // setTournamentEndDate("");
    // setTournamentEndTime("");
    setTitle("");
    setDescription("");
  };

  const updateTournament = async () => {
    try {
      setLoader(true);
      let regStarts = regStartDate + " " + regStartTime;
      let regEnds = regEndDate + " " + regEndTime;
      let tournamentStarts = tournamentStartDate + " " + tournamentStartTime;
      // let tournamentEnds = tournamentEndDate + " " + tournamentEndTime;

      // Parse the date and time strings into Moment.js objects
      const momentA = moment(regStarts, "YYYY-MM-DD HH:mm");
      const momentB = moment(regEnds, "YYYY-MM-DD HH:mm");
      const momentC = moment(tournamentStarts, "YYYY-MM-DD HH:mm");
      // const momentD = moment(tournamentEnds, "YYYY-MM-DD HH:mm");

      //Compare date and time A with date and time B
      // if (momentA.isSameOrBefore(moment())) {
      //   setLoader(false);
      //   setError("Registration starts must be after current period");
      // } else if (momentB.isSameOrBefore(momentA)) {
      //   setLoader(false);
      //   setError("Registration ends must be after Registration starts period");
      // } else if (momentC.isSameOrBefore(momentB)) {
      //   setLoader(false);
      //   setError("Tournament starts must be after Registration ends period");
      // } else {
      let data = await postUpdateTournament(
        tournamentDetail.id,
        tounamentTemplate.id,
        tounamentBracket.id,
        serverRegion.id,
        title,
        description,
        regStarts,
        regEnds,
        tournamentStarts
        // tournamentEnds
      );
      //debugger;
      if (data.data.result.status != "-1") {
        fetchTournamentList();
        setStatus({ success: true, msg: "Success" });
        setCreate(false);
      } else {
        setError(data.data.result.err_description);
      }
      console.log(data);
      setLoader(false);
      //}
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const cancelPopup = async (id) => {
    if (cancel.current) {
      cancel.current.openModal(tournamentDetail.id);
    }
  };

  const detailTournament = async (id) => {
    try {
      setError("");
      let data = await getTournamentDetails(id);
      setTournamentDetail(data?.content);
      const template = tounamentTemplateList.findIndex(
        (item) => item.id === data?.content?.tournament_template
      );
      if (template !== -1) {
        setTounamentTemplate(tounamentTemplateList[template]);
      }
      const bracket = tounamentBracketList.findIndex(
        (item) => item.id === data?.content?.braket_template
      );
      if (bracket !== -1) {
        setTounamentBracket(tounamentBracketList[bracket]);
      }
      const server = serverRegionList.findIndex(
        (item) => item.id === data?.content?.server_region
      );
      if (server !== -1) {
        setServerRegion(serverRegionList[server]);
      }
      setRegStartDate(
        moment.utc(data?.content?.registration_starts).format("YYYY-MM-DD")
      );
      setRegStartTime(
        moment.utc(data?.content?.registration_starts).format("HH:mm:ss")
      );
      setRegEndDate(
        moment.utc(data?.content?.registration_ends).format("YYYY-MM-DD")
      );
      setRegEndTime(
        moment.utc(data?.content?.registration_ends).format("HH:mm:ss")
      );
      setTournamentStartDate(
        moment.utc(data?.content?.tournament_starts).format("YYYY-MM-DD")
      );
      setTournamentStartTime(
        moment.utc(data?.content?.tournament_starts).format("HH:mm:ss")
      );
      // setTournamentEndDate(
      //   moment(data?.content?.tournament_ends).format("YYYY-MM-DD")
      // );
      // setTournamentEndTime(
      //   moment(data?.content?.tournament_ends).format("HH:mm:ss")
      // );
      setTitle(data?.content?.title);
      setDescription(data?.content?.description);
    } catch (error) {
      console.error(error);
    }
  };

  const filter = async () => {
    setLoader(true);
    await fetchTournamentList();
    setLoader(false);
  };
  return (
    <div className="flex flex-col items-center h-full p-6">
      <div className="p-6 rounded-[2px] border bg-white w-full mb-6">
        <h4 className="text-[16px] font-bold text-black">
          Filter tournaments by
        </h4>
        <div className="flex items-center gap-5 mt-2">
          <label>Created after</label>
          <div className="relative">
            <input
              type="text"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="h-[40px] px-3 border border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <div className="w-[150px]">
            <ListBoxSelect
              options={seriesOption}
              selected={series}
              setSelected={setSeries}
              placeholder="Series"
            />
          </div>
          <div className="w-[150px]">
            <ListBoxSelect
              options={seriesOption}
              selected={series}
              setSelected={setSeries}
              placeholder="Format"
            />
          </div>
          <div className="w-[150px]">
            <ListBoxSelect
              options={seriesOption}
              selected={series}
              setSelected={setSeries}
              placeholder="District Raids"
            />
          </div>
          <div className="w-[150px]">
            <ListBoxSelect
              options={seriesOption}
              selected={series}
              setSelected={setSeries}
              placeholder="Coins / token"
            />
          </div>
          <div className="w-[150px]">
            <ListBoxSelect
              options={seriesOption}
              selected={series}
              setSelected={setSeries}
              placeholder="Status"
            />
          </div>
          <button
            onClick={filter}
            className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
          >
            {loader ? (
              <BiLoaderCircle
                size={22}
                className="animate-[spin_3s_linear_infinite]"
              />
            ) : (
              "Reload"
            )}
          </button>
          <button
            onClick={openCreateTournament}
            className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
          >
            Create
          </button>
        </div>
      </div>
      <TournamentTable
        list={tounamentList}
        loader={loader}
        setCreate={setCreate}
        setType={setType}
        detailTournament={detailTournament}
      />
      {create && (
        <div className="p-6 rounded-[2px] border bg-white w-full mt-6">
          <h4 className="text-[16px] font-bold text-black mb-2">
            Tournaments setup
          </h4>
          <div className="grid grid-cols-12 gap-0 divide divide-x divide-[#bdbdbd] border border-[#bdbdbd]">
            <div className="col-span-7 ">
              <div className="grid grid-cols-2 gap-0 divide divide-x divide-[#bdbdbd]">
                <div className="p-6">
                  <label className="mb-2 block text-[14px] text-[#252525]">
                    Tournament template
                  </label>
                  <div className="">
                    <ListBoxSelect
                      options={tounamentTemplateList}
                      selected={tounamentTemplate}
                      setSelected={setTounamentTemplate}
                      placeholder="World Server PvP"
                    />
                  </div>
                </div>
                <div className="p-6">
                  <label className="mb-2 block text-[14px] text-[#252525]">
                    Secondary template
                  </label>
                  <div className="">
                    <div className="flex items-center mb-2">
                      <input
                        className="relative mr-1 h-5 w-5 appearance-none rounded-full border-2 border-solid border-gray-400 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-[#0080A6] checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-[#0080A6] checked:after:bg-[#0080A6] checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)]"
                        type="radio"
                        checked={true}
                        name="flexRadioDefault"
                        id="bracket"
                      />
                      <label
                        className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                        htmlFor="bracket"
                      >
                        Bracket system
                      </label>
                    </div>
                    <div className="">
                      <ListBoxSelect
                        options={tounamentBracketList}
                        selected={tounamentBracket}
                        setSelected={setTounamentBracket}
                        placeholder="Brackets 6 levels"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-0 divide divide-x divide-[#bdbdbd] bg-[#cccccc]">
                <div className="p-6">
                  <TournamentTemplate list={tounamentTemplate} />
                </div>
                <div className="p-6">
                  <BracketTemplate list={tounamentBracket} />
                </div>
              </div>
            </div>
            <div className="col-span-5 p-6 flex flex-col gap-3">
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Current time in (UTC)</label>
                </div>
                <div>{currentTime}</div>
              </div>
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Tounament id</label>
                </div>
                <div>(Auto)</div>
              </div>
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Tounament token</label>
                </div>
                <div>(Auto)</div>
              </div>
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Server Region</label>
                </div>
                <div>
                  <ListBoxSelect
                    options={serverRegionList}
                    selected={serverRegion}
                    setSelected={setServerRegion}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Registration starts (UTC)</label>
                </div>
                <div className="flex">
                  <div className="relative">
                    <input
                      type="date"
                      value={regStartDate}
                      min={moment().format("YYYY-MM-DD")}
                      onChange={(e) => setRegStartDate(e.target.value)}
                      className="h-[40px] w-[130px] px-3 border border-[#E8E7E4]"
                    />
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      value={regStartTime}
                      placeholder="hh/mm"
                      onChange={(e) => setRegStartTime(e.target.value)}
                      className="h-[40px] w-[100px] px-3 border border-[#E8E7E4] ml-3"
                    />
                    {/* <input
                      type="time"
                      value={regStartTime}
                      onChange={(e) => setRegStartTime(e.target.value)}
                      className="absolute inset-0 opacity-0 z-1"
                    /> */}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Registration ends (UTC)</label>
                </div>
                <div className="flex">
                  <div className="relative">
                    <input
                      type="date"
                      value={regEndDate}
                      min={moment(regStartDate).format("YYYY-MM-DD")}
                      onChange={(e) => setRegEndDate(e.target.value)}
                      className="h-[40px] w-[130px] px-3 border border-[#E8E7E4]"
                    />
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      value={regEndTime}
                      placeholder="hh/mm"
                      onChange={(e) => setRegEndTime(e.target.value)}
                      className="h-[40px] w-[100px] px-3 border border-[#E8E7E4] ml-3"
                    />
                    {/* <input
                      type="time"
                      value={regEndTime}
                      onChange={(e) => setRegEndTime(e.target.value)}
                      className="absolute inset-0 opacity-0 z-1"
                    /> */}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Tournament starts (UTC)</label>
                </div>
                <div className="flex">
                  <div className="relative">
                    <input
                      type="date"
                      value={tournamentStartDate}
                      min={moment(regEndDate).format("YYYY-MM-DD")}
                      onChange={(e) => setTournamentStartDate(e.target.value)}
                      className="h-[40px] w-[130px] px-3 border border-[#E8E7E4]"
                    />
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      value={tournamentStartTime}
                      placeholder="hh/mm"
                      onChange={(e) => setTournamentStartTime(e.target.value)}
                      className="h-[40px] w-[100px] px-3 border border-[#E8E7E4] ml-3"
                    />
                    {/* <input
                      type="time"
                      value={tournamentStartTime}
                      onChange={(e) => setTournamentStartTime(e.target.value)}
                      className="absolute inset-0 opacity-0 z-1"
                    /> */}
                  </div>
                </div>
              </div>
              {/* <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Tournament ends</label>
                </div>
                <div className="flex">
                  <div className="relative">
                    <input
                      type="date"
                      value={tournamentEndDate}
                      min={moment(tournamentStartDate).format("YYYY-MM-DD")}
                      onChange={(e) => setTournamentEndDate(e.target.value)}
                      className="h-[40px] w-[130px] px-3 border border-[#E8E7E4]"
                    />
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      value={tournamentEndTime}
                      placeholder="hh/mm"
                      onChange={(e) => setTournamentEndTime(e.target.value)}
                      className="h-[40px] w-[100px] px-3 border border-[#E8E7E4] ml-3"
                    />
                    <input
                      type="time"
                      value={tournamentEndTime}
                      onChange={(e) => setTournamentEndTime(e.target.value)}
                      className="absolute inset-0 opacity-0 z-1"
                    />
                  </div>
                </div>
              </div> */}
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Total entries</label>
                </div>
                <div>{tournamentDetail?.total_rebuys || 0}</div>
              </div>
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Total rebuys</label>
                </div>
                <div>{tournamentDetail?.total_entries || 0}</div>
              </div>
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Accumulated prize pool before rake</label>
                </div>
                <div>{tournamentDetail?.accum_prize_pool_before_rake || 0}</div>
              </div>
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="text-right">
                  <label>Accumulated prize pool for players</label>
                </div>
                <div>{tournamentDetail?.accum_prize_pool_for_players || 0}</div>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <div className="text-right">
                  <label>Title</label>
                </div>
                <div>
                  <textarea
                    type="textarea"
                    rows={2}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="p-3 border border-[#E8E7E4] w-full"
                  ></textarea>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <div className="text-right">
                  <label>Description</label>
                </div>
                <div>
                  <textarea
                    type="textarea"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="p-3 border border-[#E8E7E4] w-full"
                  ></textarea>
                </div>
              </div>              
              <p className="text-red-500 text-[12px]">{error}</p>
              <div className="mt-auto flex gap-3 justify-end">
                {type !== "create" && (
                  <button
                    onClick={cancelPopup}
                    className="h-[40px] disabled:opacity-70 px-8 text-[16px] text-black flex items-center justify-center bg-gray-300"
                  >
                    Cancel
                  </button>
                )}
                <button
                  onClick={() => {
                    if (type === "create") {
                      createTournament();
                    } else {
                      updateTournament();
                    }
                  }}
                  disabled={disabled}
                  className="h-[40px] disabled:cursor-not-allowed disabled:opacity-70 px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
                >
                  {type === "create" ? "Create data" : "Update data"}
                </button>
              </div>
              {status.success ? (
                <p className="text-green text-[12px] text-right">
                  {status.msg}
                </p>
              ) : (
                <p className="text-red-500 text-[12px] text-right">
                  {status.msg}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <CancelPopup
        ref={cancel}
        setCreate={setCreate}
        setStatus={setStatus}
        fetchTournamentList={fetchTournamentList}
      />
    </div>
  );
}
